import React from 'react'

import styles from "./ErrorField.module.css"

function ErrorField({ errorField, errorMessage }) {
  return (
    <div className={`${styles.errorContainer} ${errorField ? styles.errorTrue : styles.errorFalse}`}>
    <span>{errorMessage}</span>
    </div>
  )
}

export default ErrorField