import React from "react";
import style from "./SignIn.module.css";
import Form from "../../Components/FormularSignIn/FormularSignIn";

import { Link } from "react-router-dom";

function SignIn() {
  return (
    <div className={style.content}>
      <div className={style.logo}></div>
      <Form />
    </div>
  );
}

export default SignIn;
