import React from "react";
import style from "./LandingPage.module.css";
import Form from '../../Components/FormularSignIn/FormularSignIn'
import { Button } from "../../Components/Button/Button";
import Logo from "../../assets/images/Siglă-Wide-Fundal-Galben.png";
import { API_URL } from "../../const";
import 'primeicons/primeicons.css';

import { Link } from "react-router-dom";

function LandingPage() {
  return (
    <div className={style.contentPage}>
      <img className={style.logo} src={Logo} alt="TTJ Logo" />
      <div className={style.bottonZone}>

        <a href={`${API_URL}/api/google`} className={style.btnSignIn}>
          <i className="pi pi-google"></i>
          Continuă cu Google
          </a>

        {/* 
        <Link to="/login">
          <Button className="btnLogin">Login</Button>
        </Link> */}
      </div>
    </div>
  );
}

export default LandingPage;
