// `http://localhost:1234`; //dev
// `https://app-ttj.sisc.ro`; //live

//  export const API_URL = `http://localhost:6963`; //dev
//export const API_URL = `https://test3.sisc.ro`; // testing
export const API_URL = `https://app-ttj.sisc.ro`; //live

// export const API_URL =
// 	process.env.NODE_ENV === 'development'
// 		? 'http://localhost:1234'
// 		: 'https://app-ttj.sisc.ro';

export const filterOptions = [
  { column: "id", value: "ID" },
  { column: "firstName", value: "First Name" },
  { column: "lastName", value: "Last Name" },
  { column: "email", value: "Email" },
  { column: "telefon", value: "Phone" },
  { column: "facebook", value: "Facebook" },
  { column: "linkedin", value: "Linkedin" },
  { column: "universitate", value: "University" },
  { column: "anul", value: "Year" },
  { column: "vreiEchivalare", value: "Echivalare" }
];

export const feedbackFilterOptions = [
  { column: "id", value: "ID" },
  { column: "emailIntervievat", value: "Email Intervievat" },
  { column: "numeIntervievat", value: "Nume Intervievat" },
  { column: "numeObservator", value: "Nume Observator" },
  { column: "numeIntervievator", value: "Nume Intervievator" },
  { column: "notaExperienta", value: "Nota Experienta" },
  { column: "notaSoftSkills", value: "Nota Soft Skills" },
  { column: "notaHardSkills", value: "Nota Hard Skills" },
  { column: "notaLingvistic", value: "Nota Lingvistic" },
  { column: "notaTotal", value: "Nota Total" },
  { column: "optiunea1", value: "Optiunea 1" },
  { column: "optiunea2", value: "Optiunea 2" },
  { column: "optiunea3", value: "Optiunea 3" },
  { column: "observatii", value: "Observatii" }
];

export const testResultsDummy = [
  {
    id: 1,
    userId: 1,
    nume: "Bobita",
    prenume: "George",
    punctajTotal: 73,
    nrIntrebariCorecte: 6
  },
  {
    id: 2,
    userId: 2,
    nume: "Blandiana",
    prenume: "Ana",
    punctajTotal: 76,
    nrIntrebariCorecte: 7
  },
  {
    id: 3,
    userId: 3,
    nume: "Purcel",
    prenume: "Gigel",
    punctajTotal: 78,
    nrIntrebariCorecte: 7
  },
  {
    id: 4,
    userId: 4,
    nume: "Nume123",
    prenume: "Prenume456",
    punctajTotal: 86,
    nrIntrebariCorecte: 8
  },
  {
    id: 5,
    userId: 5,
    nume: "siscMember",
    prenume: "Smecher",
    punctajTotal: 96,
    nrIntrebariCorecte: 9
  },
  {
    id: 6,
    userId: 6,
    nume: "siscMember1",
    prenume: "Smecher1",
    punctajTotal: 93,
    nrIntrebariCorecte: 9
  },
  {
    id: 7,
    userId: 7,
    nume: "siscMember2",
    prenume: "Smecher2",
    punctajTotal: 95,
    nrIntrebariCorecte: 9
  },
  {
    id: 7,
    userId: 7,
    nume: "siscMember3",
    prenume: "Smecher3",
    punctajTotal: 96,
    nrIntrebariCorecte: 9
  },
  {
    id: 8,
    userId: 8,
    nume: "siscMember4",
    prenume: "Smecher4",
    punctajTotal: 56,
    nrIntrebariCorecte: 6
  }
];

export const rowsToDisplay = [
  { rows: 6 },
  { rows: 10 },
  { rows: 15 },
  { rows: 30 },
  { rows: 60 },
  { rows: 100 }
];

export const parteneri = ["Ubisoft", "BCR", "EY"];

export const tipTest = [
  {
    tip: "Multiple Choice",
    value: true
  },
  {
    tip: "Open Answer",
    value: false
  }
];

export let nrIntrebari = [];

for (let i = 3; i <= 25; i++) {
  nrIntrebari.push(i);
}

export const nrRaspunsuri = [3, 4, 5, 6];

export class Intrebare {
  nrIntrebare = 0;
  text = "";
  nrRaspunsuri = 0;
  punctaj = 0;
  raspunsuri = [];

  constructor(nrIntrebare, text, nrRaspunsuri, punctaj, raspunsuri) {
    this.nrIntrebare = nrIntrebare;
    this.text = text;
    this.nrRaspunsuri = nrRaspunsuri;
    this.punctaj = punctaj;
    this.raspunsuri = raspunsuri;
  }
}

export class Raspuns {
  text = "";
  esteCorect = false;

  constructor(text, esteCorect) {
    this.text = text;
    this.esteCorect = esteCorect;
  }
}
