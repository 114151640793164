import RootProvider from "./context/RootProvider";
import Paths from "../src/Paths/Paths";
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';

function App() {
  return (
    <RootProvider>
      <Paths />
    </RootProvider>
  );
}

export default App;