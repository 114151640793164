export const initialStateInputs = {
  nume: { value: "", valid: false },
  prenume: { value: "", valid: false },
  telefon: { value: "", valid: false },
  facebook: { value: "", valid: false },
  linkedin: { value: "", valid: false },
  universitate: { value: null, valid: false },
  specializare: { value: null, valid: false },
  anUniversitar: { value: null, valid: false },
  practica: { value: null, valid: false },
  CV: { value: null, valid: false },
};

export const initialStateErrors = {
  errorNume: false,
  errorPrenume: false,
  errorTelefon: false,
  errorFacebook: false,
  errorLinkedin: false,
  errorUniversitate: false,
  errorSpecializare: false,
  errorAnUniversitar: false,
  errorPractica: false,
  errorCV: false,
};

export const regex = {
  numeRegex:
    /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšșțăžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮȘŢĂŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/,
  prenumeRefex:
    /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšșțăžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮȘŢĂŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/,
  emailRegex:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  parolaRegex: /^(?=.*?[a-z])(?=.*?[0-9]).{8,32}$/,
  telefonRegex: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
  facebookRegex:
    /(?:https?:\/\/)?(?:www\.)?(mbasic.facebook|m\.facebook|facebook|fb)\.(com|me)\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-\.]*\/)*([\w\-\.]*)/,
  linkedinRegex:
    /((https?:\/\/)?((www|\w\w)\.)?linkedin\.com\/)((([\w]{2,3})?)|([^\/]+\/(([\w|\d-&#?=])+\/?){1,}))$/,
  facultateRegex:
    /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšșțăžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮȘŢĂŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/,
};

export const listaUniversitati = [
  {
    universitate: "Academia de Studii Economice din București",
    universitateSelectata: "ASE",
  },
  {
    universitate: "Universitatea POLITEHNICA din București",
    universitateSelectata: "Politehnica",
  },
  {
    universitate: "Universitatea din București",
    universitateSelectata: "Universitate",
  },
  { universitate: "Alta Universitate", universitateSelectata: "Alta" },
];

export const listaSpecializari = [
  {
    specializare: "Cibernetică Economică",
    specializareSelectata: "Cibernetica",
  },
  {
    specializare: "Informatică Economică",
    specializareSelectata: "Informatica",
  },
  { specializare: "Statistică Economică", specializareSelectata: "Statistică" },
  { specializare: "Altă Specializare", specializareSelectata: "Alta" },
];

export const listaAnUniversitar = [
  { anUniversitar: "1", anSelectat: "1" },
  { anUniversitar: "2", anSelectat: "2" },
  { anUniversitar: "3", anSelectat: "3" },
  { anUniversitar: "4", anSelectat: "4" },
  { anUniversitar: "Master 1", anSelectat: "5" },
  { anUniversitar: "Master 2", anSelectat: "6" },
];

export const listaPractica = [
  { optiunePractica: "DA", value: 1 },
  { optiunePractica: "NU", value: 0 },
];
