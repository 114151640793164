import React, { useRef, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import style from "./FormularRegister.module.css";
import { RootContext } from "../../context/RootProvider";

import { AiOutlineCheck } from "react-icons/ai";

import ErrorField from "./ErrorField/ErrorField";

import {
  initialStateInputs,
  initialStateErrors,
  listaUniversitati,
  listaSpecializari,
  listaAnUniversitar,
  listaPractica
} from "./Constants/const";
import { regex } from "./Constants/const";

import { registerUser } from "../../utils/apiCalls";
import { Toaster, toast } from "react-hot-toast";

export function FormularRegister() {
    const rootContext = useContext(RootContext)
    const setUser = rootContext.setUser;    
      const user = rootContext.user;

  const [inputObject, setInputObject] = useState(initialStateInputs);
  const [errorObject, setErrorObject] = useState(initialStateErrors);

  const [GDPR, setGDPR] = useState(false);
  const [errorGDPR, setErrorGDPR] = useState(false);
  const [regulament, setRegulament] = useState(false);
  const [errorRegulament, setErrorRegulament] = useState(false);

  const navigate = useNavigate();

  const timeoutIDRef = useRef();

  const {
    nume,
    prenume,
    telefon,
    facebook,
    linkedin,
    universitate,
    specializare,
    anUniversitar,
    practica,
    CV
  } = inputObject;

  const {
    errorNume,
    errorPrenume,
    errorTelefon,
    errorFacebook,
    errorLinkedin,
    errorUniversitate,
    errorSpecializare,
    errorAnUniversitar,
    errorPractica
  } = errorObject;

  const errorMessageNume =
    nume.value === "" ? "This is a required field" : "Invalid last name";
  const errorMessagePrenume =
    prenume.value === "" ? "This is a required field" : "Invalid first name";
  const errorMessageTelefon =
    telefon.value === "" ? "This is a required field" : "Invalid phone number";
  const errorMessageFacebook =
    facebook.value === "" ? "This is a required field" : "Invalid Facebook";
  const errorMessageLinkedin = linkedin.value === "" ? "" : "Invalid Linkedin";
  const errorMessageUniversitate =
    universitate.value === null ? "This is a required field" : "";
  const errorMessageSpecializare =
    specializare.value === null ? "This is a required field" : "";
  const errorMessageAnUniversitar =
    anUniversitar.value === null ? "This is a required field" : "";
  const errorMessagePractica =
    practica.value === null ? "This is a required field" : "Invalid name";

  const uploadCVText = CV.value ? "CV Incarcat" : "Incarca CV";

  function checkValidity(fieldObject, errorField, delay) {
    const { valid } = fieldObject;

    if (delay > 0) {
      clearTimeout(timeoutIDRef.current);
    }

    if (valid) {
      setErrorObject(prevErrorObj => {
        return { ...prevErrorObj, [errorField]: false };
      });
    } else {
      timeoutIDRef.current = setTimeout(() => {
        setErrorObject(prevErrorObj => {
          return { ...prevErrorObj, [errorField]: true };
        });
      }, delay);
    }
  }

  function onInputChange(field, value, testExpression, errorField) {
    const valueEmpty = value === "" || value === null ? true : false;

    let isValid;
    if (testExpression) {
      isValid = value.match(testExpression) !== null ? true : false;
    }

    const newFieldObj = {
      value: value,
      valid: testExpression ? isValid : !valueEmpty
    };

    setInputObject(prevInputObject => {
      return { ...prevInputObject, [field]: newFieldObj };
    });

    checkValidity(newFieldObj, errorField, 2000);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setErrorGDPR(!GDPR);
    setErrorRegulament(!regulament);

    let validInputs = true;

    for (const field in inputObject) {

      const { valid: validField } = inputObject[field];
      if (validField === false && field !== "linkedin") {
        validInputs = false;
      }

      let currentErrorField;

      for (const errorField in errorObject) {
        if (errorField.toLowerCase().includes(field.toLowerCase())) {
          currentErrorField = errorField;
          break;
        }
      }

      if (field !== "linkedin") {
        checkValidity(inputObject[field], currentErrorField, 0);
      }


      if (field === "facebook" && !facebook.valid) {
        toast.error(
          "Invalid facebook link"
        );

      }
    }
    if (validInputs === true && GDPR === true && regulament === true) {
        const dataSent = {
          firstName: prenume.value,
          lastName: nume.value,
          phoneNumber: telefon.value,
          facebook: facebook.value,
          university: universitate.value.universitate,
          linkedin: linkedin.value,
          facultySpecialization: specializare.value.specializare,
          facultyYear: anUniversitar.value.anUniversitar,
          eqPractice: practica.value,
          email:user.email,
          isCompleteProfile: true
        };

        

        if (CV.value.size > 5000000) {
          toast.error("CV are peste 5 mb!");
        } else {
          try {

            const receivedData = await registerUser(CV.value, dataSent);
            setUser(receivedData.data)
            toast.success("Te-ai înregistrat cu succes! Înapoi la pagina principală...");
            setTimeout(() => navigate(`/profile`), 2000);
          } catch (err) {
            console.error(err.message)
            const errors = err.response.data.errors
            for (const error of errors)
              toast.error(error);
          }
        }

      }
      else if (CV.valid === false) {
        toast.error("Trebuie să încarci un CV. Îl poți schimba mai târziu!")
      }

  }

  function handleCheckboxChange(e) {
    if (e.target.name === "regulament") {
      const currentRegulamentStatus = !regulament;

      setRegulament(currentRegulamentStatus);

      if (currentRegulamentStatus === false) {
        setErrorRegulament(true);
      } else {
        setErrorRegulament(false);
      }
    } else if (e.target.name === "gdpr") {
      const currentGDPRStatus = !GDPR;

      setGDPR(currentGDPRStatus);

      if (currentGDPRStatus === false) {
        setErrorGDPR(true);
      } else {
        setErrorGDPR(false);
      }
    }
  }

  return (
    <>
      <div className={style.container}>
        <div className={style.registerCard}>
          <h1 className={style.header}>Înregistrează-te</h1>

          <h3 className={style.important}>IMPORTANT!! Dacă ai completat formularul de preînscriere, nu este nevoie să-l completezi
            și pe cel de înscriere.</h3>

          <form onSubmit={handleSubmit} className={style.registerForm}>
            <div className={style.fieldContainer}>
              <ErrorField
                errorField={errorNume}
                errorMessage={errorMessageNume}
              />
              <span className="p-float-label">
                <InputText
                  className={style.input}
                  id="nume"
                  value={nume.value}
                  onChange={e =>
                    onInputChange(
                      "nume",
                      e.target.value,
                      regex.numeRegex,
                      "errorNume"
                    )
                  }
                />
                <label htmlFor="nume">Nume*</label>
              </span>
            </div>

            <div className={style.fieldContainer}>
              <ErrorField
                errorField={errorPrenume}
                errorMessage={errorMessagePrenume}
              />
              <span className="p-float-label">
                <InputText
                  className={style.input}
                  id="prenume"
                  value={prenume.value}
                  onChange={e =>
                    onInputChange(
                      "prenume",
                      e.target.value,
                      regex.prenumeRefex,
                      "errorPrenume"
                    )
                  }
                />
                <label htmlFor="prenume">Prenume*</label>
              </span>
            </div>

            <div className={style.fieldContainer}>
              <ErrorField
                errorField={errorTelefon}
                errorMessage={errorMessageTelefon}
              />
              <span className="p-float-label">
                <InputText
                  className={style.input}
                  id="telefon"
                  value={telefon.value}
                  onChange={e =>
                    onInputChange(
                      "telefon",
                      e.target.value,
                      regex.telefonRegex,
                      "errorTelefon"
                    )
                  }
                />
                <label htmlFor="telefon">Telefon*</label>
              </span>
            </div>

            <div className={style.fieldContainer}>
              <ErrorField
                errorField={errorFacebook}
                errorMessage={errorMessageFacebook}
              />
              <span className="p-float-label">
                <InputText
                  className={style.input}
                  id="facebook"
                  value={facebook.value}
                  onChange={e =>
                    onInputChange(
                      "facebook",
                      e.target.value,
                      regex.facebookRegex,
                      "errorFacebook"
                    )
                  }
                />
                <label htmlFor="facebook">Facebook*</label>
              </span>
            </div>

            <div className={style.fieldContainer}>
              <ErrorField
                errorField={errorLinkedin}
                errorMessage={errorMessageLinkedin}
              />
              <span className="p-float-label">
                <InputText
                  className={style.input}
                  id="linkedin"
                  value={linkedin.value}
                  onChange={e =>
                    onInputChange(
                      "linkedin",
                      e.target.value,
                      regex.linkedinRegex,
                      "errorLinkedin"
                    )
                  }
                />
                <label htmlFor="linkedin">LinkedIn</label>
              </span>
            </div>

            <div className={style.fieldContainer}>
              <ErrorField
                errorField={errorUniversitate}
                errorMessage={errorMessageUniversitate}
              />
              <span className="p-float-label">
                <Dropdown
                  value={universitate.value}
                  className={style.input}
                  options={listaUniversitati}
                  onChange={e =>
                    onInputChange(
                      "universitate",
                      e.target.value,
                      "",
                      "errorUniversitate"
                    )
                  }
                  optionLabel="universitate"
                  placeholder="Selecteaza universitatea"
                />
                <label htmlFor="universitate">Universitate*</label>
              </span>
            </div>

            <div className={style.fieldContainer}>
              <ErrorField
                errorField={errorSpecializare}
                errorMessage={errorMessageSpecializare}
              />
              <span className="p-float-label">
                <Dropdown
                  value={specializare.value}
                  className={style.input}
                  options={listaSpecializari}
                  onChange={e =>
                    onInputChange(
                      "specializare",
                      e.target.value,
                      "",
                      "errorSpecializare"
                    )
                  }
                  optionLabel="specializare"
                  placeholder="Selecteaza specializarea"
                />
                <label htmlFor="specializare">Specializare*</label>
              </span>
            </div>

            <div className={style.fieldContainer}>
              <ErrorField
                errorField={errorAnUniversitar}
                errorMessage={errorMessageAnUniversitar}
              />
              <span className="p-float-label">
                <Dropdown
                  value={anUniversitar.value}
                  className={style.input}
                  options={listaAnUniversitar}
                  onChange={e =>
                    onInputChange(
                      "anUniversitar",
                      e.target.value,
                      "",
                      "errorAnUniversitar"
                    )
                  }
                  optionLabel="anUniversitar"
                  placeholder="Selecteaza anul"
                />
                <label htmlFor="anul">Anul*</label>
              </span>
            </div>

            <div className={style.fieldContainer}>
              <ErrorField
                errorField={errorPractica}
                errorMessage={errorMessagePractica}
              />
              <span className="p-float-label">
                <Dropdown
                  value={practica.value}
                  className={style.input}
                  options={listaPractica}
                  onChange={e =>
                    onInputChange(
                      "practica",
                      e.target.value,
                      "",
                      "errorPractica"
                    )
                  }
                  optionLabel="optiunePractica"
                  placeholder="Vrei să îți echivalezi practica?"
                />
                <label htmlFor="practica">
                  Vrei să îți echivalezi practica?*
                </label>
              </span>
            </div>

            <div className={style.containerCV}>
              <label
                htmlFor="CV"
                className={`${style.buttonCV} ${CV.value ? style.buttonCVUploaded : style.buttonCVNotUploaded
                  }`}>
                {uploadCVText}
              </label>
              {CV.value && (
                <p className={style.fileText}>
                  Fisier: <span className={style.file}>{CV.value.name}</span>
                </p>
              )}
              <div className={style.inputCV}>
                <input
                  onChange={e =>
                    onInputChange("CV", e.target.files[0], "", "errorCV")
                  }
                  type="file"
                  name="CV"
                  id="CV"
                  accept="application/pdf"
                />
              </div>
            </div>

            <div className={style.checkboxContainer}>
              <input
                type="checkbox"
                name="regulament"
                id="regulament"
                onClick={handleCheckboxChange}
              />
              <p className={style.checkboxText}>
                Am citit și sunt de acord cu (
                <a
                  href="https://docs.google.com/document/d/1Dd1DIPddefRCJBBhC7SVTa8yjwFC98jypDhHnyRegm0/edit#heading=h.cv8raz1u9pyc"
                  target="_blank"
                  rel="noreferrer">
                  regulamentul proiectului
                </a>
                ).
              </p>
            </div>

            <p
              className={`${style.checkboxError} ${errorRegulament ? style.checkboxErrorActive : null
                }`}>
              Trebuie sa accepti regulamentul pentru a te inregistra
            </p>

            <div className={style.checkboxContainer}>
              <input
                type="checkbox"
                name="gdpr"
                id="gdpr"
                onClick={handleCheckboxChange}
              />
              <p className={style.checkboxText}>
                Sunt de acord cu prelucrarea datelor personale (
                <a
                  href="https://docs.google.com/document/d/17-4O9imQTvXcg1fvEBY87maHLUI8wLxgltFMYB5HjU4/edit"
                  target="_blank"
                  rel="noreferrer">
                  General Data Protection Regulation - GDPR
                </a>
                ).
              </p>
            </div>

            <p
              className={`${style.checkboxError} ${errorGDPR ? style.checkboxErrorActive : null
                }`}>
              Trebuie sa accepti termenii pentru a te inregistra
            </p>

            <div className={style.buttonContainer}>
              <Button
                type="submit"
                label="Submit"
                className={style.buttonSubmitRegister}
                aria-label="Submit"
              />
            </div>
          </form>
        </div>
      </div>
      <Toaster />
    </>
  );
}